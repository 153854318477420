var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',[_vm._v("Olá "+_vm._s(_vm.user.nome))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","to":"/promotor/dashboard"}},[_vm._v(" MINHA DASHBOARD ")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" MEUS ACERTOS "),_c('v-spacer'),_c('search',{on:{"applyFilters":_vm.loadData}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.meusAcertos,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.pedidoIds",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.pedidoIds.length))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))])]}},{key:"item.totalPedidos",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalPedidos)))])]}},{key:"item.checkout.boletoUrl",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","dark":""}},[_c('a',{staticClass:"white--text text-decoration-none",attrs:{"href":item.checkout.boletoUrl,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v(" PDF ")],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"rounded-0",attrs:{"dense":"","dark":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Id Pedido")]),_c('th',[_vm._v("Data")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',_vm._l((item.items),function(i){return _c('tr',{key:i.id},[_c('td',[_vm._v(_vm._s(i.pedidoId))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(i.pedido.dataPedido)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(i.pedido.totalLiquido)))])])}),0)])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }