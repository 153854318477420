<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <p
          class="text-right"
        >
          <v-btn
            color="primary"
            class="mr-4"
            to="/promotor/dashboard"
          >
            MINHA DASHBOARD
          </v-btn>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          outlined
        >
          <v-card-title>
            MEUS ACERTOS
            <v-spacer />
            <search @applyFilters="loadData" />
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="model.meusAcertos"
              :single-expand="true"
              :expanded.sync="expanded"
              item-key="id"
              show-expand
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.pedidoIds="{ item }">
                <td>{{ item.pedidoIds.length }}</td>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.createdAt="{ item }">
                <td>{{ item.createdAt | date }}</td>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.totalPedidos="{ item }">
                <td>{{ item.totalPedidos | currency }}</td>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.checkout.boletoUrl="{ item }">
                <v-chip
                  color="primary"
                  dark
                >
                  <a
                    :href="item.checkout.boletoUrl"
                    target="_blank"
                    class="white--text text-decoration-none"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                    PDF
                  </a>
                </v-chip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0"
                >
                  <v-simple-table
                    class="rounded-0"
                    dense
                    dark
                  >
                    <thead>
                      <tr>
                        <th>Id Pedido</th>
                        <th>Data</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="i in item.items"
                        :key="i.id"
                      >
                        <td>{{ i.pedidoId }}</td>
                        <td>{{ i.pedido.dataPedido | date }}</td>
                        <td>{{ i.pedido.totalLiquido | currency }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Search from '../components/Search'

  export default {
    components: {
      Search,
    },
    data: () => ({
      expanded: [],
      headers: [
        { text: 'Número de Pedidos', value: 'pedidoIds' },
        { text: 'Data', value: 'createdAt' },
        { text: 'Valor', value: 'totalPedidos' },
        { text: 'Status Pagamento', value: 'checkout.statusPagamento' },
        { text: 'Boleto', value: 'checkout.boletoUrl' },
        { text: '', value: 'data-table-expand' },
      ],
      model: {
        meusAcertos: [],
      },
    }),
    computed: {
      ...mapGetters(['user']),
    },
    mounted () {
      this.cleanUpModel()
    },
    methods: {
      cleanUpModel () {
        this.model.meusAcertos = []

        this.loadData()
      },
      loadData () {
        this.$http.get('promotor/acerto')
          .then(({ data }) => {
            this.model.meusAcertos = data
          })
      },
    },
  }
</script>
